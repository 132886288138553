import { t } from "@lingui/macro";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { HomePage } from "../../../pages";
import { Locale } from "../../../types/Locale";
import AppStoreBadge from "../AppStoreBadge";
import PlayStoreBadge from "../PlayStoreBadge";
import { Link } from "../../../components/Link/Link";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { WaitButton } from "../../../components/WaitButton/WaitButton";
import { useOnboardingWelcome } from "./useOnboardingWelcome";

export default function OnboardingWelcomePage() {
  const imageData = useStaticQuery(graphql`
    query {
      tablet: file(relativePath: { eq: "tablet-lady.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 1920, placeholder: DOMINANT_COLOR)
        }
      }
      webQuizImageEN: file(relativePath: { eq: "web-quiz-image.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
      webQuizImageSE: file(relativePath: { eq: "web-quiz-image-se.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
      webQuizImageES: file(relativePath: { eq: "web-quiz-image-es.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
    }
  `);

  const { locale } = useContext(pageContext);
  const tt = useTranslations();
  const { isBusy } = useOnboardingWelcome();

  return (
    <>
      <SEOReactHelmet noindex title="Diet Doctor - Welcome Onboarding" lang={locale as string} />

      {/* Mobile */}
      <div className="!block md:!hidden bg-green-500 pt-14">
        <h2 className="font-medium text-center text-dark-blue text-3xl m-0 whitespace-pre-line px-4">
          {tt.congratulationsHeading}
        </h2>
        <p className="font-medium text-center text-darker-grey text-xl m-0 mt-4 px-[2.625rem]">
          {tt.congratulationsText}
        </p>

        <div className="flex flex-row justify-center mt-7">
          <a
            href="https://apps.apple.com/app/diet-doctor-eat/id1440002708"
            target="_blank"
            rel="noreferrer"
            className="mr-3"
          >
            <AppStoreBadge className="h-10 md:h-12" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.dietdoctor.EatApp"
            target="_blank"
            rel="noreferrer"
          >
            <PlayStoreBadge className="h-10 md:h-12" />
          </a>
        </div>

        <div className="mt-14 w-full overflow-hidden">
          {locale === Locale.EN && (
            <GatsbyImage
              image={imageData.webQuizImageEN?.childImageSharp?.gatsbyImageData}
              className="w-full h-full"
              objectFit="cover"
              style={{
                transform: "translateX(4.4%) translateY(0%) scale(1.205)",
              }}
              alt=""
            />
          )}
          {locale === Locale.SV && (
            <GatsbyImage
              image={imageData.webQuizImageSE?.childImageSharp?.gatsbyImageData}
              className="w-full h-full"
              objectFit="cover"
              style={{
                transform: "translateX(4.4%) translateY(0%) scale(1.205)",
              }}
              alt=""
            />
          )}
          {locale === Locale.ES && (
            <GatsbyImage
              image={imageData.webQuizImageES?.childImageSharp?.gatsbyImageData}
              className="w-full h-full"
              objectFit="cover"
              style={{
                transform: "translateX(4.4%) translateY(0%) scale(1.205)",
              }}
              alt=""
            />
          )}
        </div>

        <div className="bg-white pt-14">
          <h3 className="font-medium text-center text-dark-blue text-3xl m-0 whitespace-pre-line px-6">
            {tt.checkOutMealPlan}
          </h3>
          {isBusy ? (
            <WaitButton />
          ) : (
            <Link
              to={HomePage}
              className="bg-green rounded-full py-3 px-6 text-white font-medium block w-max mx-auto mt-8"
            >
              {tt.letsGo}
            </Link>
          )}

          <GatsbyImage
            image={imageData.tablet?.childImageSharp?.gatsbyImageData}
            className="w-full h-full min-h-[12.5rem] mt-14"
            objectFit="cover"
            alt=""
          />
        </div>
      </div>

      {/* Desktop */}
      <div className="!hidden md:!flex flex-col bg-green-500 min-h-screen">
        <div className="md:relative">
          <GatsbyImage
            image={imageData.tablet?.childImageSharp?.gatsbyImageData}
            className="w-full min-h-[38vh] md:min-h-[50vh]"
            alt=""
          />
          <div className="bg-white rounded-2xl relative md:absolute inset-0 mx-auto bottom-0 md:my-auto max-w-xs md:max-w-xl h-min flex-col items-center text-center shadow-xl p-8 md:p-7 -mt-40">
            <h2 className="font-medium text-3xl md:text-4xl m-0">{tt.congratulations}</h2>
            <p className="text-lg md:text-xl m-0 mt-6 whitespace-pre-line">{tt.allDone}</p>
            {isBusy ? (
              <WaitButton />
            ) : (
              <Link
                to={HomePage}
                className="bg-green rounded-full py-3 px-6 text-white font-medium block w-max mx-auto mt-8 md:mt-10"
              >
                {tt.goTo}
              </Link>
            )}
          </div>
        </div>

        <div className="flex-1">
          <div className="flex flex-col md:flex-row items-center max-w-6xl mx-auto md:space-x-4 md:px-6 md:pt-2">
            <div className="w-full md:w-1/2 text-center md:text-left mt-14 md:mt-0">
              <h3 className="display-s m-0 text-[#37664E] md:text-dark-blue">{tt.fullExperience}</h3>
              <p className="body-xl m-0 dark-blue max-w-sm pr-3 my-5 mb-7 mx-auto md:mx-0">{tt.download}</p>
              <div className="flex flex-row justify-center md:justify-start">
                <a
                  href="https://apps.apple.com/app/diet-doctor-eat/id1440002708"
                  target="_blank"
                  rel="noreferrer"
                  className="mr-3"
                >
                  <AppStoreBadge className="h-10 md:h-12" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.dietdoctor.EatApp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <PlayStoreBadge className="h-10 md:h-12" />
                </a>
              </div>
            </div>

            <div className="w-full md:w-1/2 overflow-hidden">
              {locale === Locale.EN && (
                <GatsbyImage
                  image={imageData.webQuizImageEN?.childImageSharp?.gatsbyImageData}
                  className="w-full h-full"
                  objectFit="cover"
                  alt=""
                />
              )}
              {locale === Locale.SV && (
                <GatsbyImage
                  image={imageData.webQuizImageSE?.childImageSharp?.gatsbyImageData}
                  className="w-full h-full"
                  objectFit="cover"
                  alt=""
                />
              )}
              {locale === Locale.ES && (
                <GatsbyImage
                  image={imageData.webQuizImageES?.childImageSharp?.gatsbyImageData}
                  className="w-full h-full"
                  objectFit="cover"
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function useTranslations() {
  return {
    congratulationsHeading: t({
      id: "OnboardingWelcomePage.congratulationsHeading",
      message: "Congratulations!\nYou are all set",
    }),
    congratulationsText: t({
      id: "OnboardingWelcomePage.congratulationsText",
      message: "Download the app to get the most out of your membership.",
    }),

    congratulations: t({
      id: "OnboardingWelcomePage.congratulations",
      message: "Congratulations!",
    }),

    checkOutMealPlan: t({
      id: "OnboardingWelcomePage.checkOutMealPlan",
      message: "Check out your personalized meal plan.",
    }),
    letsGo: t({
      id: "OnboardingWelcomePage.letsGo",
      message: "Let’s go!",
    }),

    allDone: t({
      id: "OnboardingWelcomePage.allDone",
      message: "You’re all done.\nClick below for your personalized meal plan.",
    }),
    goTo: t({
      id: "OnboardingWelcomePage.goTo",
      message: "Go to my meal plan",
    }),

    fullExperience: t({
      id: "OnboardingWelcomePage.fullExperience",
      message: "Get the full experience!",
    }),
    download: t({
      id: "OnboardingWelcomePage.download",
      message: "Download the Diet Doctor app to get the most out of your membership.",
    }),
  };
}
