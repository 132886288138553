import { FetchResult, MutationResult, useMutation } from "@apollo/client";
import { MealPlanner_ActivateMealPlanMutation } from "../../types/MealPlanner_ActivateMealPlanMutation";
import ActivateMealplanMutation from "../ActivateMealPlan.graphql";

type UseActivateMealplanReturnType = [
  (id: string) => Promise<FetchResult<MealPlanner_ActivateMealPlanMutation>>,
  MutationResult<MealPlanner_ActivateMealPlanMutation>
];

export const useActivateMealplan = (): UseActivateMealplanReturnType => {
  const [mutateFn, mutationResult] = useMutation<MealPlanner_ActivateMealPlanMutation>(
    ActivateMealplanMutation,
    {
      awaitRefetchQueries: true,
    }
  );
  const activateMealPlan = (id: string) => {
    return mutateFn({
      variables: {
        id,
      },
    });
  };
  return [activateMealPlan, mutationResult];
};
