/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from "react";
import OnboardingWelcomePage from "./OnboardingWelcomePage/OnboardingWelcomePage";
import { isBrowser } from "../../utils/ssr";
import AppStoreBadge from "./AppStoreBadge";
import PlayStoreBadge from "./PlayStoreBadge";
import { LoggedInHomePage } from "../../pages";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "../../components/Link/Link";
import { NavigationBar } from "../../components/NavBar/NavigationBar";
import { useTranslations } from "./translations";

// ts-prune-ignore-next
export default function WelcomePage() {
  if (!isBrowser()) {
    return null;
  }

  const tt = useTranslations();

  const queryStringContent = useMemo(() => new URLSearchParams(window.location.search)?.get("content"), []);

  if (queryStringContent === "personalized-meal-plan" || queryStringContent === "onboarding") {
    return <OnboardingWelcomePage />;
  } else {
    return (
      <>
        <NavigationBar />

        <section className="w-full gradient bg-gradient-to-b from-blue-200 to-white">
          <div className="max-w-screen-xl mx-auto">
            <div className="grid grid-flow-row md:grid-flow-col md:grid-cols-2 md:py-28 md:gap-y-14 min-h-screen-without-header">
              <div className="flex justify-center md:hidden mx-auto max-w-lg px-4 py-2 sm:py-0">
                <StaticImage
                  src="../../../static/images/welcome/welcome-banner-app-mobile.png"
                  loading="eager"
                  width={720}
                  objectFit="contain"
                  alt="banner mobile image"
                />
              </div>

              <div className="pl-5 pr-5 xl:pl-0 flex flex-col items-center md:items-start">
                <h1 className="text-center md:text-left text-black mb-0 mt-4 md:mt-8 whitespace-pre-line">
                  {tt.welcomePageHeader}
                </h1>
                <p className="text-center md:text-left text-xl md:text-2xl mt-4 md:mt-12 max-w-lg whitespace-pre-line">
                  {tt.welcomePageContent}
                </p>

                <div className="flex flex-col items-center sm:flex-row sm:space-x-3 mt-6">
                  <a
                    href="https://apps.apple.com/app/diet-doctor-eat/id1440002708"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AppStoreBadge height="54" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.dietdoctor.EatApp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PlayStoreBadge height="54" />
                  </a>
                </div>

                <Link
                  to={LoggedInHomePage}
                  className="text-green inline-block font-medium mt-8 md:mt-14 mb-4 md:bg-green md:text-white md:rounded-full md:py-3 md:px-[1.375rem]"
                >
                  {tt.welcomePageSkipLinkText}
                </Link>
              </div>
              <div className="hidden md:block">
                <StaticImage
                  src="../../../static/images/welcome/welcome-banner-app-desktop.png"
                  loading="eager"
                  width={720}
                  objectFit="contain"
                  alt="banner desktop image"
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
