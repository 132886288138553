import { t } from "@lingui/macro";

export function useTranslations() {
  return {
    welcomePageHeader: t({
      id: "WelcomePage.header",
      message: "Success!\nYou’ve got full access",
    }),
    welcomePageContent: t({
      id: "WelcomePage.content",
      message: "Download the app to enjoy our\nbest features at your fingertips.",
    }),
    welcomePageSkipLinkText: t({
      id: "WelcomePage.skipLinkText",
      message: "Continue to your homepage",
    }),
  };
}
