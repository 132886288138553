import { FetchResult, MutationResult, useMutation } from "@apollo/client";
import { CreateMealplan } from "../../types/CreateMealplan";
import { MealplanInput } from "../../types/graphql-global-types";
import CreateMealplanMutation from "./CreateMealplan.graphql";

type UseCreateMealplanReturnType = [
  (input: MealplanInput) => Promise<FetchResult<CreateMealplan>>,
  MutationResult<CreateMealplan>
];

export const useCreateMealplan = (): UseCreateMealplanReturnType => {
  const [mutateFn, mutationResult] = useMutation<CreateMealplan>(CreateMealplanMutation, {
    awaitRefetchQueries: true,
  });
  const createMealplan = (input: MealplanInput) => {
    return mutateFn({
      variables: {
        mealplan: input,
      },
    });
  };
  return [createMealplan, mutationResult];
};
