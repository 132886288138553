import { MutationResult } from "@apollo/client";
import { useMachine } from "@xstate/react";
import { useContext, useMemo, useEffect } from "react";
import { createMachine, DoneInvokeEvent } from "xstate";
import { useActivateMealplan } from "../../../graphql/useActivateMealPlan/useActivateMealPlan";
import { useCreateMealplan } from "../../../graphql/useCreateMealplan/useCreateMealplan";
import { useStateWithLocalStorage } from "../../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";
import { useOnboardingStore } from "../../onboarding/hooks/useOnboardingStore";
import { CreateMealplan } from "../../../types/CreateMealplan";
import { MealplanInput } from "../../../types/graphql-global-types";
import { tryOrUndefined } from "../../../utils/utils";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";

const welcomeMachine = createMachine({
  initial: "waiting",
  states: {
    waiting: {
      on: {
        USER_STATUS_CHANGE: "onboarding",
      },
    },
    onboarding: {
      invoke: {
        src: "updateOnboardingData",
        onDone: "mealPlan",
        onError: "ready", // even if the onboarding data update fails, we proceed, the user can update later
      },
    },
    mealPlan: {
      invoke: {
        src: "createFirstMealPlan",
        onDone: "activate",
        onError: "ready", // even if the first meal plan fails, we proceed, the user can create a new one later
      },
    },
    activate: {
      invoke: {
        src: "activateFirstMealPlan",
        onDone: "ready",
      },
    },
    ready: {
      type: "final",
    },
  },
});

export function useOnboardingWelcome() {
  const { loading: isUserLoading, firebaseUserId } = useContext(LoginContext);
  const [anonymousMealPlanInput] = useStateWithLocalStorage<string>(
    "dd/onboarding/anonymous-meal-plan-input",
    "{}"
  );

  const input = tryOrUndefined<MealplanInput>(() => JSON.parse(anonymousMealPlanInput));
  const [createMealplan] = useCreateMealplan();
  const [activateMealplan] = useActivateMealplan();
  const { update: updateOnboardingData } = useOnboardingStore();

  const [state, send] = useMachine(welcomeMachine, {
    services: {
      updateOnboardingData: (_, e) => updateOnboardingData({ firebaseUserId: e.firebaseUserId }),
      createFirstMealPlan: () => createMealplan(input ?? {}),
      activateFirstMealPlan: (_, e) => {
        const typed = e as DoneInvokeEvent<MutationResult<CreateMealplan>>;
        if (typed.data !== null && !!typed.data.data) {
          return activateMealplan(typed.data.data.createMealplan.id);
        }
        // even if the meal plan cannot be activated, we proceed
        return Promise.resolve();
      },
    },
  });

  const isBusy = useMemo(() => !state.matches("ready"), [state]);

  useEffect(() => {
    if (!isUserLoading && typeof firebaseUserId !== "undefined") {
      send({ type: "USER_STATUS_CHANGE", firebaseUserId });
    }
  }, [isUserLoading, firebaseUserId, send]);

  return { isBusy };
}
