import React from "react";
import { LoadingIcon } from "../Icon/Icon";

export function WaitButton() {
  return (
    <div className="w-60 h-12 bg-grey rounded-full py-3 px-6 text-white font-medium mx-auto mt-8 md:mt-10 flex justify-center items-center">
      <LoadingIcon />
    </div>
  );
}
